<template>
  <div>
    <Alert v-if="isDisabled" type="info">
      <span>Não é permitido alterar as regras ou as informações do usuário <b>Adm</b>.</span>
    </Alert>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <InputText title="Nome" field="name" :disabled="isDisabled" :formName="formName" :required="true"
            :maxLength="50" v-model="user.name">
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <InputText title="E-mail" field="email" :disabled="isDisabled" :formName="formName" :required="true"
            :maxLength="50" v-model="user.email">
            <div v-if="user.isConfirmedEmail" class="glyphicon" v-b-tooltip.hover title="Confirmado">
              <i class="icon-success fa-solid fa-circle-check"> </i>
            </div>
            <div v-else class="glyphicon margin-button" v-b-tooltip.hover title="Enviar confirmação">
              <Button _key="sendMailConfirm" color="white" backGroundColor="#F6BA45"
                classIcon="fa-solid fa-triangle-exclamation" size="small" :params="{ typeConfirm: 'email' }"
                :clicked="sendConfirm" />
            </div>
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <InputText title="Celular" :disabled="isDisabled" field="mobile" :formName="formName"
            :mask="['(##) ####-####', '(##) #####-####']" :required="false" :maxLength="50" v-model="user.mobile">
            <div v-if="user.mobile">
              <div v-if="user.isConfirmedMobile" class="glyphicon" v-b-tooltip.hover title="Confirmado">
                <i class="icon-success fa-solid fa-circle-check"> </i>
              </div>
              <div v-else class="glyphicon margin-button" v-b-tooltip.hover title="Enviar confirmação">
                <Button _key="sendMobileConfirm" color="white" backGroundColor="#F6BA45"
                  classIcon="fa-solid fa-triangle-exclamation" size="small" :params="{ typeConfirm: 'mobile' }"
                  :clicked="sendConfirm" />
              </div>
            </div>
          </InputText>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputText title="Login" :disabled="isDisabled" field="userName" :formName="formName" :required="true"
            :maxLength="50" v-model="user.userName">
          </InputText>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <InputPassword :disabled="isDisabled" title="Senha" field="password" :formName="formName" :required="true"
            :maxLength="50" v-model="user.password">
          </InputPassword>
        </b-col>
      </b-row>
      <b-row v-if="id && user.type == 2">
        <b-col sm="12">
          <RadioGroup title="Status" field="status" :formName="formName" :options="[
            { text: 'Ativo', value: 1 },
            { text: 'Inativo', value: 2 },
          ]" v-model="user.status" />
        </b-col>
      </b-row>
      <b-row v-if="userLogged.user.type == 1 && user.type == 1">
        <b-col sm="12">
          <div>
            <Button _key="btnTransfer" title="Tranferir Administrador" type="danger"
              classIcon="fa-solid fa-triangle-exclamation" size="small" :clicked="transferAdm" />
          </div>
        </b-col>
      </b-row>
    </Molded>
    <br />
    <b-tabs v-if="id && !isDisabled">
      <b-tab title="Regras">
        <UserRule :userType="user.type" :disabled="isDisabled" />
      </b-tab>
    </b-tabs>

    <Modal title="Transferir Administrador" :width="600" :height="750" v-if="showModal('transfer')">
      <Alert type="warning">
        <span>Atenção: ao transferir sua regra de Adm, você não será mais responsável pela segurança e
          permissões.</span>
      </Alert>
      <Select title="Transferir para" field="user" :formName="formName" :required="true"
        url="/api/v1/adm/user/select-all" :showNewRegister="false" v-model="userTransferTarget" />

      <InputText title="Digite Confirmar para alterar" v-model="inputConfirm" />
      <div class="text-center">
        <Button _key="btnSaveTransfer" :disabled="inputConfirm != 'Confirmar'" title="Aplicar" type="primary"
          size="small" :clicked="saveTransfer" />
      </div>
    </Modal>


  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputWhatsApp from "@nixweb/nixloc-ui/src/component/forms/InputWhatsApp";
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";;

import UserRule from "./UserRule.vue";

import User from "@/components/modules/adm/user/User.js";

import { mapMutations, mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "UserCreateUpdate",
  components: {
    Molded,
    InputText,
    InputWhatsApp,
    InputPassword,
    UserRule,
    RadioGroup,
    Button,
    Alert,
    Modal,
    Select
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "user",
      user: new User(),
      inputConfirm: "",
      userTransferTarget: {},
      urlCreate: "/api/v1/adm/user/create",
      urlUpdate: "/api/v1/adm/user/update",
      urlGetById: "/api/v1/adm/user/get-by-id",
      urlConfirm: "/api/v1/adm/auth/sendconfirm-mail-mobile",
      urlTransferAdm: "/api/v1/adm/user/transfer-adm",
    };
  },
  created() {
    if (!this.id) this.removeLoading(["panel"]);
    if (this.id) this.getById();
  },
  methods: {
    ...mapMutations("validation", ["removeFormDirty"]),
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    ...mapMutations("user", ["addUserLogged"]),
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    create() {
      let params = { url: this.urlCreate, obj: this.user };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.push({
            name: "userUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.user };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.getById();
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { userId: this.id } };
      this.getApi(params).then((response) => {
        if (response.success) {
          this.user.update(response.content);
          let self = this;
          setTimeout(function () {
            self.removeLoading(["panel"]);
            self.removeFormDirty();
          }, 100);
        }
      });
    },
    sendConfirm(obj) {
      let params = {
        url: this.urlConfirm,
        obj: {
          typeConfirm: obj.typeConfirm,
          email: this.user.email,
          mobile: this.user.mobile,
        },
      };
      this.postApi(params).then((response) => {
        this.removeLoading(["sendMailConfirm", "sendMobileConfirm"]);
      });
    },
    transferAdm() {
      this.openModal("transfer");
      this.removeLoading(["btnTransfer"]);
    },
    saveTransfer() {
      let params = {
        url: this.urlTransferAdm,
        obj: { id: this.userLogged.user.id, any: this.userTransferTarget.id },
        notNotifyToast: true
      };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addUserLogged({});
          localStorage.setItem("codeBrowser", "");
          window.location.replace("/login");
        }
        this.removeLoading(["btnSaveTransfer"]);
      });
    }
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
    ...mapState("user", ["userLogged"]),
    isDisabled() {
      if (this.userLogged.user.id == this.user.id) {
        return false;
      } else {
        if (this.userLogged.user.type == 2 && this.user.type == 1) {
          return true;
        }
      }
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.icon-success {
  color: green;
}

.icon-warning {
  color: #f6ba45;
}
</style>