<template>
  <div>
    <div v-for="rule in rules" :key="rule.id">
      <Molded>
        <b-row>
          <b-col>
            <div class="permission">
              <Toggle :title="rule.namePortuguese" :color="'#577696'" classIcon="fa-duotone fa-lock" :params="rule"
                :disabled="disabled || (rule.name === 'Adm' && userType === 1)" v-model="rule.hasRule"
                :changed="changed" />
            </div>
          </b-col>
        </b-row>
        <div class="permission" v-if="rule.hasRule && rule.name != 'Adm'">
          <CheckboxGroup field="permission" :disabled="true" :markFormDirty="false" :options="options(rule.name)"
            :initialValue="rule.permission" v-model="permission" :params="{ ruleId: rule.ruleId }"
            :clicked="createDeletePermission" />
        </div>
      </Molded>
      <br />
    </div>
  </div>
</template>

<script>
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapActions } from "vuex";

export default {
  name: "Rules",
  components: { Toggle, CheckboxGroup, Molded },
  props: {
    rules: Array,
    userType: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    urlCreate: String,
    urlDelete: String,
    urlCreateDeletePermission: String,
  },
  data() {
    return {
      id: this.$route.params.id,
      permission: [],
      optionRules: [
        {
          name: "Cashier",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "CRM",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "Stock",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "Maintenance",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "Supplier",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "Finance",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
        {
          name: "Billing",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Cancelar/Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
        {
          name: "Signature",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
        {
          name: "Billet",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Baixar/Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
        {
          name: "Planner",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "PDV",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Alterar Valor", value: "update" },
            { text: "Abrir Caixa", value: "openCashier" },
            { text: "Fechar Caixa", value: "closeCashier" },
          ],
        },
        {
          name: "Operational",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
            { text: "Transferir Todos", value: "transferAll" },
            { text: "Dashboard", value: "dashboard" }
          ],
        },
        {
          name: "Rental",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
        {
          name: "HumanResources",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Remover", value: "delete" },
          ],
        },
        {
          name: "TaxDocument",
          permission: [
            { text: "Adicionar", value: "create" },
            { text: "Modificar", value: "update" },
            { text: "Cancelar/Remover", value: "delete" },
            { text: "Dashboard", value: "dashboard" },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions("generic", ["postApi", "deleteApi"]),
    changed(rule) {
      let userRule = { ...rule, ...{ userId: this.id } };
      if (rule.hasRule) this.create(userRule);
      if (!rule.hasRule) this.delete(userRule);
    },
    create(rule) {
      let params = { url: this.urlCreate, obj: rule, notNotifyToast: true };
      this.postApi(params).then((response) => { });
    },
    delete(rule) {
      let url = `${this.urlDelete}?userId=${this.id}&ruleId=${rule.ruleId}`;
      let params = { url: url, obj: rule, notNotifyToast: true };
      this.deleteApi(params).then((response) => { });
    },
    createDeletePermission(rule) {
      let obj = {
        userId: this.id,
        ruleId: rule.ruleId,
        permission: this.permission,
      };
      let params = {
        url: this.urlCreateDeletePermission,
        obj: obj,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => { });
    },
    options(name) {
      var options = [];

      this.optionRules.forEach((option) => {
        if (option.name == name) {
          option.permission.forEach((permission) => {
            options.push(permission);
          });
        }
      });
      return options;
    },
  },
};
</script>
